import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

Sentry.init({
  release: import.meta.env.VITE_SENTRY_RELEASE,
  enabled: !import.meta.env.DEV,
  environment: import.meta.env.VITE_SENTRY_ENV,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      mask: [
        "input[type='password']",
        "input[type='text'][name*='card']",
        "input[type='text'][name*='credit']",
        "input[type='text'][name*='cc']",
        "input[type='tel'][name*='card']",
        "input[type='tel'][name*='credit']",
        "input[type='tel'][name*='cc']",
      ],
      blockAllMedia: false,
    }),
  ],

  // Tracing
  tracesSampleRate: import.meta.env.VITE_SENTRY_ENV.startsWith('production') ? 0.1 : 0.3,
  tracePropagationTargets: [new RegExp(`/^${import.meta.env.VITE_BACKEND_API_BASE_URL}`)],

  // Session Replay
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 0.1,
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
